import { inject, Injectable } from '@angular/core';
import { of, shareReplay, startWith, Subject, switchMap, take, tap } from 'rxjs';
import type { Observable } from 'rxjs';

import type { UpdateUser, User } from 'prayer-rotation-base';

import { ApiService } from './api/api.service';
import { AuthenticationService } from './authentication.service';
import type { AsyncState } from '../models/state';
import { buildFulfilledAsyncState } from '../utilities/state/build-async-state';
import { wrapWithAsyncState } from '../utilities/state/wrap-with-async-state';

@Injectable( { providedIn: 'root' } )
export class UserService {
  private readonly apiService = inject( ApiService );
  private readonly authenticationService = inject( AuthenticationService );

  private readonly invalidateCurrentUserCache = new Subject< void >();
  readonly currentUserStateObservable = this.invalidateCurrentUserCache
    .pipe( startWith( undefined ) )
    .pipe( switchMap( () =>
      this.authenticationService.currentSessionStateObservable
        .pipe( switchMap( sessionState =>
          sessionState.fulfilled
            ? sessionState.result !== undefined
              ? this.apiService.getUser( sessionState.result.userId ).pipe( wrapWithAsyncState() )
              : of( buildFulfilledAsyncState< User | undefined >( undefined ) )
            : of( sessionState )
        ) )
    ) )
    .pipe( shareReplay( { bufferSize: 1, refCount: true } ) );

  updateCurrentUser( user: UpdateUser ): Observable< AsyncState > {
    return this.authenticationService.assertCurrentUserIdObservable
      .pipe( take( 1 ) )
      .pipe( switchMap( currentUserId => this.apiService.updateUser( currentUserId, user ) ) )
      .pipe( tap( () => this.invalidateCurrentUserCache.next() ) )
      .pipe( wrapWithAsyncState() );
  }
}
